// @ts-nocheck

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import LeftImg from "../../../assets/image/left.svg";
import EuroImg from "../../../assets/image/euro.svg";
import CheckImg from "../../../assets/image/check.svg"
import PdfImg from "../../../assets/image/pdf.svg";
import CallingImg from "../../../assets/image/calling.svg"
import CalcImg from "../../../assets/image/calculator-outline.svg"
import TimerImg from "../../../assets/image/timer.svg";
import DollarImg from "../../../assets/image/dollar.svg"
import NextImg from "../../../assets/image/next_black.svg";
import PrevImg from "../../../assets/image/prev_black.svg";
import People1Img from "../../../assets/image/people1.png"
import HandDollarImg from "../../../assets/image/hand_dollar.svg";
import PeopleImg from "../../../assets/image/people.png"
import CarImg from "../../../assets/image/car.svg";
import CityImg from "../../../assets/image/city.svg";
import PictureImg from "../../../assets/image/picture.svg";

import ProgressBar from "../../../components/progressbar";
import CustomButton from "../../../components/button";
import NormalSwitch from "../../../components/normal_switch";
import KeyPoints from "../../../components/keypoints";
import Carosuel3 from "../../../components/carosuel3";
import { carsState, dollarFormatter, dollarFormatter2, getFileUrl, projectState, showConfirmBox } from "../../../utils/utils";
import CarInfoCard from "../../../components/car_info_card";
import CustomInput from "../../../components/input";
import { toast } from "react-toastify";
import { addActivity, addInvestment, getCarInfo, getConfigs, getUserInfo } from "../../../services/axios";
import LoadingSpinner from "../../../components/loading";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import SimulationDialog from "../../../components/account/simulation_dialog";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../../utils/constants";
import ReactApexChart from "react-apexcharts";
import PhotoGallery from "./PhotoGallery";



const _barChartOptions = {
    chart: {
        width: '100%',
        height: 200,
        type: 'bar',
        toolbar: {
            show: false,
        }
    },
    colors: ["#2d9cf9"],
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="px-2 py-1">+' + series[seriesIndex][dataPointIndex] + '%</div>';
        }
    },
    plotOptions: {
        bar: {
            borderRadius: 2,
            distributed: false,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: true,
        showForSingleSeries: true,
        position: 'top',
    },
    yaxis: {
        show: true,
        labels: {
            formatter: (value) => { return value + "%" }
        }
    },
    xaxis: {
        categories: ["Nov 2024", "May 2025", "Nov 2024", "May 2025", "Nov 2024"]
    }
}
const _profitChartData = [{
    name: "Income Paid Out",
    data: [0, 5, 10, 15, 20]
}];


export default function CarsDetail(): JSX.Element {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();
    const { carId } = useParams();

    const [configs, setConfigs] = useState(null);
    const [carItem, setCarItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [generalSelected, setGeneralSelected] = useState(0);

    const [buyAmount, setBuyAmount] = useState(0);
    const [feeAmount, setFeeAmount] = useState(0);
    const [isSimulation, setIsSimulation] = useState(false);

    const [barChartOptions, setBarChartOptions] = useState(_barChartOptions);
    const [profitChartData, setProfitChartData] = useState(_profitChartData);

    const [seeMore, setSeeMore] = useState(true);

    const [isOpenGallery, setIsOpenGallery] = useState(false);

    useEffect(() => {
        if (carId) {
            loadCarInfo();
        }
    }, [carId]);

    const loadCarInfo = async () => {
        setIsLoading(true);
        const resC = await getConfigs();
        if (resC.status === 200) {
            setConfigs(resC.data);
        }

        const res = await getCarInfo(carId);
        if (res.status === 200) {
            let info = res.data.result;
            setCarItem(info);

            // set graph data
            if (info.endAt) {
                let categories = [];
                let profits = [];

                let monthlyRev = info.fixedRevenue / 12;
                let startDate = new Date(info.endAt);
                for (let i = 0; i <= info.horizonRefund * 12; i += 6) {
                    let date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 10);
                    categories.push(dayjs(date).format(DATETIME_FORMAT.MONTH_STR));
                    profits.push(Number((monthlyRev * i).toFixed(1)));
                }

                setBarChartOptions({
                    ...barChartOptions,
                    xaxis: {
                        categories: categories
                    }
                });
                setProfitChartData([{
                    name: "Income Paid Out",
                    data: profits
                }]);
            }
        }
        setIsLoading(false);
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleAddBuyAmount = async (percent) => {
        let onePercentVal = carItem.amountFunded / 100;
        let newAmount = buyAmount + onePercentVal * percent;
        if (newAmount > carItem.amountFunded) {
            newAmount = carItem.amountFunded;
        }
        setBuyAmount(newAmount);
    }
    useEffect(() => {
        let feePercent = configs?.depositFee || 5;
        let newFee = buyAmount / 100 * feePercent;
        setFeeAmount(newFee);
    }, [buyAmount]);

    const handleBuyCar = async () => {
        const balance = Number(user?.cryptoBalance);
        const amount = Number(buyAmount + feeAmount);
        if (amount <= 0) {
            return toast.error("Please input the correct amount!");
        }
        if (amount > balance) {
            return toast.error("Insufficient balance!");
        }

        showConfirmBox("Information", "Are you sure to buy car?",
            async () => {
                setIsLoading(true);

                const carId = carItem?._id;
                const description = `Purchase ${carItem?.name}`;
                const res = await addInvestment({
                    carId, amount, description
                });

                if (res.status === 200) {
                    toast.success("Succeed!");
                    addActivity("Invest");

                    // reload car info
                    loadCarInfo();

                    // user info update
                    const res2 = await getUserInfo(user.token);
                    if (res2.status === 200) {
                        console.log(res2.data);

                        updateUser(res2.data);
                    }
                } else {
                    toast.error(res.data.message);
                }
                setIsLoading(false);
            });
    }

    const handleViewPhotos = () => {
        setIsOpenGallery(true);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="flex flex-col items-center p-[20px] mt-[90px]">
                <div className="w-full xl:w-[1200px] md:mt-[60px] ">
                    <div className="w-full flex flex-row items-center justify-between">
                        <button className="flex flex-row items-center" onClick={handleBack}>
                            <img src={LeftImg} alt="alt" />
                            <p className="font-Urbanist text-unseletedText text-[16px] font-[600] ml-[7px]">
                                Go back
                            </p>
                        </button>
                        <CustomButton variant="filled" className="bg-white w-[196px] h-[42px] font-Montserrat text-unseletedText normal-case text-[16px] font-[600] p-0 " text={carsState[carItem?.status]} />
                    </div>
                    <div className="w-full flex flex-col lg:flex-row items-center justify-center mt-[27px] gap-10 relative cursor-pointer" onClick={handleViewPhotos}>
                        <img src={getFileUrl(carItem?.photos[0].path)} alt="car01" className="w-full lg:w-1/2" />
                        {carItem?.photos.length > 1 &&
                            <img src={getFileUrl(carItem?.photos[1].path)} alt="car02" className="w-full lg:w-1/2" />
                        }
                        <div className="flex justify-content items-center gap-2 right-0 bottom-0 m-[18px] py-3 px-4 bg-white rounded-lg absolute">
                            <img src={PictureImg} alt="view" className="" />
                            View {carItem?.photos?.length} photos
                        </div>
                    </div>
                    {
                        isTabletOrMobile &&
                        <div className="flex flex-col  md:w-1/2 mx-auto ">
                            <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                <div className="flex flex-row items-center justify-between" >
                                    <p className="font-Urbanist font-[600] text-[24px] text-black5">
                                        {dollarFormatter(carItem?.amountFunded)}
                                    </p>
                                    <div className="flex items-center justify-center rounded-full bg-background5 p-[8px_12px] text-unseletedText font-Urbanist text-[16px] font-[600]">
                                        {projectState[carItem?.status]}
                                    </div>
                                </div>
                                <p className="font-Urbanist text-unseletedText text-[16px] font-[500] mt-[12px]">
                                    Amount to fund
                                </p>
                                {(carItem?.status === 2) &&
                                    <>
                                        <div className="flex flex-row items-center justify-between mt-[48px]" >
                                            <p className="font-Urbanist font-[600] text-[16px] text-black5">
                                                {dollarFormatter2(carItem?.fundsRaised)} funded
                                            </p>
                                            <p className="font-Urbanist font-[600] text-[16px] text-unseletedText">
                                                {carItem?.progress}%
                                            </p>
                                        </div>
                                        <ProgressBar progress={carItem?.progress} />
                                        <div className="w-full mt-[32px] ">
                                            <div className="w-full flex flex-row">
                                                <div className="w-1/2">
                                                    <CustomInput
                                                        placeholder="Buy Amount"
                                                        crossOrigin={undefined}
                                                        value={buyAmount > 0 ? buyAmount : ''}
                                                        className="w-full" size="lg"
                                                        containerClassName="!min-w-[100px]"
                                                        onChange={(e: any) => setBuyAmount(e.target.value)}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <CustomInput
                                                        placeholder="Fee"
                                                        crossOrigin={undefined}
                                                        value={feeAmount > 0 ? '+ ' + feeAmount : ''}
                                                        containerClassName="!min-w-[100px]"
                                                        className="w-full" size="lg"
                                                        onChange={() => { }}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-row flex-wrap">
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] w-[60px] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.1)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.1%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.25)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.25%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.5)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.5%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(1)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 1%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(2)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 2%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(5)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">+ 5%</div>
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => setBuyAmount(0)}>
                                                    <div className="flex items-center justify-center text-nowrap text-[14px]">Clear</div>
                                                </CustomButton>
                                            </div>
                                        </div>
                                        <CustomButton variant="filled" className="bg-black w-full h-[50px] font-Montserrat text-white rounded-full normal-case text-[16px] font-[500] p-0 mt-[32px]" text="Buy Cars" onClick={handleBuyCar} />
                                        <div className="flex flex-row items-center gap-20 mt-[20px]">
                                            <div className="relative">
                                                {carItem.investors?.length > 0 &&
                                                    <img crossOrigin={undefined} src={carItem.investors[0].avatar || LogoImage} alt={carItem.investors[0].fullname} className="w-[48px] h-[48px] rounded-full border border-white" />
                                                }
                                                {carItem.investors?.length > 1 &&
                                                    <img crossOrigin={undefined} src={carItem.investors[1].avatar || LogoImage} alt={carItem.investors[1].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[24px]" />
                                                }
                                                {carItem.investors?.length > 2 &&
                                                    <img crossOrigin={undefined} src={carItem.investors[2].avatar || LogoImage} alt={carItem.investors[2].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[48px]" />
                                                }
                                            </div>
                                            <p className="font-Urbanist text-[16px] font-[600] text-unseletedText">
                                                {carItem?.investerCount} investors have invested on Hulk
                                            </p>
                                        </div>
                                    </>
                                }
                                <CustomButton variant="outlined" className=" rounded-[8px] border-[1.5px] border-opacity-10 border-black w-full h-[50px]  normal-case  p-0 mt-[32px]" text="" onClick={() => setIsSimulation(true)} >
                                    <div className="flex flex-row items-center justify-center">
                                        <img src={CalcImg} alt="calculate" />
                                        <p className="text-black5 text-[16px] font-[500] font-Urbanist ">
                                            Simulate my investment
                                        </p>
                                    </div>
                                </CustomButton>
                                <div className="w-full h-[1px] bg-mainback mt-[40px]">
                                </div>
                                <div className="flex flex-row gap-2 mt-[32px]">
                                    <img src={TimerImg} alt="timer" />
                                    <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                        Collection open since {dayjs(carItem?.startAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.startAt).format(DATETIME_FORMAT.TIME_24)}. Will close on {dayjs(carItem?.endAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.endAt).format(DATETIME_FORMAT.TIME_24)}.
                                    </p>
                                </div>
                                <div className="flex flex-row gap-2 mt-[32px]">
                                    <img src={DollarImg} alt="timer" />
                                    <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                        Any amount invested will be refunded in full in the event of an unsuccessful fundraising.
                                    </p>
                                </div>

                            </div>

                        </div>
                    }
                    <div className="mt-[32px] flex flex-col items-center">
                        <NormalSwitch text1="General" text2="Finance" setAllCarsSelected={setGeneralSelected} state={generalSelected} />
                    </div>
                    {
                        generalSelected === 0 ?
                            <div className="flex flex-col lg:flex-row  justify-between gap-10">
                                <div className="flex flex-col items-center md:w-1/2   mt-[28px]">
                                    <div className="w-full flex flex-row items-center justify-between">
                                        <CarInfoCard title={carItem?.fixedRevenue + '%'} text={'Fixed revenue /year'} info={'Enjoy regular income'} content={'Monthly payouts'} />
                                        <CarInfoCard title={carItem?.horizonRefund + ' years'} text={'Horizon'} info={'Recover 100% of the capital.'} content={'Repayment at maturity'} />
                                    </div>
                                    <div className="w-full  bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                                            Key points
                                        </p>
                                        <KeyPoints carItem={carItem} />
                                        {/* <div className="flex flex-row items-center mt-[34px] px-[20px]">
                                            <p className="font-Urbanist text-[16px] font-[600] text-black5">
                                                See the transaction note
                                            </p>
                                            <button className="ml-[10px]">
                                                <img src={OpenImg} alt="open" />
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="w-full  bg-white mt-[30px] rounded-[12px] p-[20px]">

                                        <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                                            Presentation
                                        </p>
                                        <div className="flex flex-wrap items-center gap-2 justify-between mt-[32px]">
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                                    <img src={CarImg} alt="gate" />
                                                </div>
                                                <p className="font-Urbanist text-[16px] font-[600] text-black">
                                                    {carItem?.presentation?.info}
                                                </p>
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                                    <img src={EuroImg} alt="gate" />
                                                </div>
                                                <p className="font-Urbanist text-[16px] font-[600] text-black">
                                                    Car value: {dollarFormatter(carItem?.presentation?.carValue)}
                                                </p>
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                                    <img src={CityImg} alt="gate" />
                                                </div>
                                                <p className="font-Urbanist text-[16px] font-[600] text-black">
                                                    City: {carItem?.presentation?.city}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="font-Urbanist text-[16px] font-[600] text-black mt-[24px]">
                                            Presentation of the Project Owner
                                        </p>
                                        <p className=" font-Urbanist text-[14px] md:text-[16px] font-[500] text-unseletedText mt-[24px]">
                                            {seeMore ? carItem?.presentation?.owner?.substring(0, 200) : carItem?.presentation?.owner}
                                        </p>
                                        {carItem?.presentation?.owner?.length > 200 &&
                                            <button className="underline  font-Urbanist text-[14px] md:text-[16px] font-[500] text-unseletedText mt-[24px]"
                                                onClick={() => setSeeMore(!seeMore)}>
                                                {seeMore ? 'See More' : 'See Less'}
                                            </button>
                                        }
                                    </div>
                                    <div className="w-full  bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="flex  flex-row items-center gap-4">
                                            {carItem?.ownerPresentation?.image &&
                                                <img src={getFileUrl(carItem?.ownerPresentation?.image?.path)} alt="person" className="w-[36px] h-[36px] md:w-[64px] md:h-[64px]" />
                                            }
                                            <div className="flex flex-col ">
                                                <p className="text-black3 text-[18px] md:text-[20px] font-[600] font-Urbanist">
                                                    Presentation of the Project Owner
                                                </p>
                                                <p className="text-unseletedText text-[14px] font-[500] font-Urbanist">
                                                    {carItem?.ownerPresentation?.name}
                                                </p>
                                            </div>
                                        </div>
                                        {carItem?.ownerPresentation?.infos?.map((item, index) =>
                                            <div key={index} className="flex flex-row gap-3 mt-[20px]">
                                                <div className="flex items-center justify-center bg-background4 w-[28px] h-[28px] rounded-full">
                                                    <img src={CheckImg} alt="check" className="w-[14px] h-[14px]" />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <p className="text-black3 text-[16px] md:text-[18px] font-[600] font-Urbanist">
                                                        {item.title}
                                                    </p>
                                                    <p className="text-unseletedText text-[14px] font-[500] break-words font-Urbanist">
                                                        {item.content}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="w-full flex flex-row items-center  justify-between">
                                            <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                                                Project Timeline
                                            </p>
                                            <div className=" flex flex-row items-center gap-3">
                                                {/* <CustomButton variant="outlined" className="flex items-center justify-center bg-background4 w-[32px] h-[32px] p-0 rounded-full">
                                                    <img src={PrevImg} alt="next" />
                                                </CustomButton>
                                                <CustomButton variant="outlined" className="flex items-center justify-center bg-background4 w-[32px] h-[32px] p-0 rounded-full">
                                                    <img src={NextImg} alt="next" />
                                                </CustomButton> */}
                                            </div>
                                        </div>
                                        <div className="w-full overflow-hidden">
                                            <div className="h-[1px] w-full bg-[#E5E5E5] mt-[32px]">
                                            </div>
                                            <div className="flex flex-row items-center gap-20">
                                                <Carosuel3 startAt={carItem?.startAt} endAt={carItem?.endAt} timeline={carItem?.timeline} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full p-[20px] bg-white mt-[30px] rounded-[12px]">
                                        <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                                            Documents
                                        </p>
                                        <div className="flex flex-wrap gap-4 mt-[20px]">
                                            {carItem?.proofDoc &&
                                                <div className="flex flex-row gap-[6px] items-center p-[8px_12px] bg-background4 rounded-[6px] cursor-pointer"
                                                    onClick={() => window.open(getFileUrl(carItem?.proofDoc.path), "_blank")}
                                                >
                                                    <img src={PdfImg} alt="pdf" />
                                                    <p className="text-[16px] font-500 text-black font-Urbanist">
                                                        Proof of Purchase
                                                    </p>
                                                </div>
                                            }
                                            {carItem?.investSheet &&
                                                <div className="flex flex-row gap-[6px] items-center p-[8px_12px] bg-background4 rounded-[6px] cursor-pointer"
                                                    onClick={() => window.open(getFileUrl(carItem?.investSheet.path), "_blank")}
                                                >
                                                    <img src={PdfImg} alt="pdf" />
                                                    <p className="text-[16px] font-500 text-black font-Urbanist">
                                                        Information Sheet
                                                    </p>
                                                </div>
                                            }
                                            {carItem?.nftTerms &&
                                                <div className="flex flex-row gap-[6px] items-center p-[8px_12px] bg-background4 rounded-[6px] cursor-pointer"
                                                    onClick={() => window.open(getFileUrl(carItem?.nftTerms.path), "_blank")}
                                                >
                                                    <img src={PdfImg} alt="pdf" />
                                                    <p className="text-[16px] font-500 text-black font-Urbanist">
                                                        Terms and Conditions of NFT
                                                    </p>
                                                </div>
                                            }
                                            {carItem?.registrationDoc &&
                                                <div className="flex flex-row gap-[6px] items-center p-[8px_12px] bg-background4 rounded-[6px] cursor-pointer"
                                                    onClick={() => window.open(getFileUrl(carItem?.registrationDoc.path), "_blank")}
                                                >
                                                    <img src={PdfImg} alt="pdf" />
                                                    <p className="text-[16px] font-500 text-black font-Urbanist">
                                                        Car Registration
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="relative w-full flex flex-row items-center justify-between bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="w-full flex flex-col items-center md:items-start">
                                            {
                                                isTabletOrMobile &&
                                                <img src={People1Img} alt="people" />
                                            }
                                            <p className=" text-black3 text-[20px] font-[600] font-Urbanist text-center md:text-start">
                                                Do you have any questions?
                                            </p>
                                            <p className="w-[250px] text-unseletedText text-[14px] font-[500] font-Urbanist mt-[16px] text-center md:text-start">
                                                Our customer service is on hand to help you at any time.
                                            </p>
                                            <div className="w-[230px] flex flex-row gap-2 rounded-full mt-[28px] border-[1px] border-black border-opacity-10 bg-[#F2F4F6] p-[10px_16px] cursor-pointer" onClick={() => {
                                                window.open('https://calendly.com/hulkcars/30min');
                                            }}>
                                                <img src={CallingImg} alt="phone" />
                                                <p className="font-Urbanist text-black text-[16px] font-[500]">
                                                    I want to be called back
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            !isTabletOrMobile &&
                                            <>
                                                <div className="w-1/2">
                                                </div>
                                                <img src={PeopleImg} alt="people" className="absolute right-0" />
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    !isTabletOrMobile &&
                                    <div className="flex flex-col  md:w-1/2 ">
                                        <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                            <div className="flex flex-row items-center justify-between" >
                                                <p className="font-Urbanist font-[600] text-[24px] text-black5">
                                                    {dollarFormatter(carItem?.amountFunded)}
                                                </p>
                                                <div className="flex items-center justify-center rounded-full bg-background5 p-[8px_12px] text-unseletedText font-Urbanist text-[16px] font-[600]">
                                                    {projectState[carItem?.status]}
                                                </div>
                                            </div>
                                            <p className="font-Urbanist text-unseletedText text-[16px] font-[500] mt-[12px]">
                                                Amount to fund
                                            </p>
                                            {(carItem?.status === 2) &&
                                                <>
                                                    <div className="flex flex-row items-center justify-between mt-[48px]" >
                                                        <p className="font-Urbanist font-[600] text-[16px] text-black5">
                                                            {dollarFormatter2(carItem?.fundsRaised)} funded
                                                        </p>
                                                        <p className="font-Urbanist font-[600] text-[16px] text-unseletedText">
                                                            {carItem?.progress}%
                                                        </p>
                                                    </div>
                                                    <ProgressBar progress={carItem?.progress} />
                                                    <div className="w-full mt-[32px]">
                                                        <div className="w-full flex flex-row">
                                                            <CustomInput
                                                                placeholder="Buy Amount"
                                                                crossOrigin={undefined}
                                                                value={buyAmount > 0 ? buyAmount : ''}
                                                                className="w-full" size="lg"
                                                                onChange={(e: any) => setBuyAmount(e.target.value)}
                                                                readOnly
                                                            />
                                                            <CustomInput
                                                                placeholder="Fee"
                                                                crossOrigin={undefined}
                                                                value={feeAmount > 0 ? '+ ' + feeAmount : ''}
                                                                className="w-full" size="lg"
                                                                onChange={() => { }}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="w-full flex flex-row flex-wrap">
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] w-[60px] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.1)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.1%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.25)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.25%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.5)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.5%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(1)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 1%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(2)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 2%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(5)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 5%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => setBuyAmount(0)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">Clear</div>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                    <CustomButton variant="filled" className="bg-black w-full h-[50px] font-Montserrat text-white rounded-full normal-case text-[16px] font-[500] p-0 mt-[20px]" text="Buy Cars" onClick={handleBuyCar} />
                                                    <div className="flex flex-row items-center gap-20 mt-[32px]">
                                                        <div className="relative">
                                                            {carItem.investors?.length > 0 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[0].avatar || LogoImage} alt={carItem.investors[0].fullname} className="w-[48px] h-[48px] rounded-full border border-white" />
                                                            }
                                                            {carItem.investors?.length > 1 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[1].avatar || LogoImage} alt={carItem.investors[1].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[24px]" />
                                                            }
                                                            {carItem.investors?.length > 2 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[2].avatar || LogoImage} alt={carItem.investors[2].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[48px]" />
                                                            }
                                                        </div>
                                                        <p className="font-Urbanist text-[16px] font-[600] text-unseletedText">
                                                            {carItem?.investerCount} investors have invested on Hulk
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                            <CustomButton variant="outlined" className=" rounded-[8px] border-[1.5px] border-opacity-10 border-black w-full h-[50px]  normal-case  p-0 mt-[32px]" text="" onClick={() => setIsSimulation(true)}>
                                                <div className="flex flex-row items-center justify-center">
                                                    <img src={CalcImg} alt="calculate" />
                                                    <p className="text-black5 text-[16px] font-[500] font-Urbanist ">
                                                        Simulate my investment
                                                    </p>
                                                </div>
                                            </CustomButton>
                                            <div className="w-full h-[1px] bg-mainback mt-[40px]">
                                            </div>
                                            <div className="flex flex-row gap-2 mt-[32px]">
                                                <img src={TimerImg} alt="timer" />
                                                <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                                    Collection open since {dayjs(carItem?.startAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.startAt).format(DATETIME_FORMAT.TIME_24)}. Will close on {dayjs(carItem?.endAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.endAt).format(DATETIME_FORMAT.TIME_24)}.
                                                </p>
                                            </div>
                                            <div className="flex flex-row gap-2 mt-[32px]">
                                                <img src={DollarImg} alt="timer" />
                                                <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                                    Any amount invested will be refunded in full in the event of an unsuccessful fundraising.
                                                </p>
                                            </div>

                                        </div>

                                    </div>
                                }
                            </div>
                            :
                            <div className="flex flex-col lg:flex-row  justify-between gap-10">
                                <div className="flex flex-col items-center md:w-1/2 ">
                                    <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="flex flex-col items-start">
                                            <div className="flex flex-row items-center">
                                                <div className="w-[44px] h-[44px] bg-background4 rounded-full flex flex-col items-center justify-center">
                                                    <img src={CarImg} alt="car" />
                                                </div>
                                                <p className="text-black3 text-[20px] font-[600] font-Urbanist ml-3">
                                                    Total Rentability
                                                </p>
                                            </div>
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] ">
                                                    Annual Rentability
                                                </p>
                                                <p className="font-Urbanist text-[16px] ">
                                                    {carItem?.fixedRevenue}%
                                                </p>
                                            </div>
                                            {/* <div className="h-[1px] w-full" /> */}
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] ">
                                                    Investment Horizon
                                                </p>
                                                <p className="font-Urbanist text-[16px] ">
                                                    {carItem?.horizonRefund} years
                                                </p>
                                            </div>
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] text-[#2C89DB] font-[600]">
                                                    Total Rentability over {carItem?.horizonRefund} years
                                                </p>
                                                <p className="font-Urbanist text-[16px]  text-[#2C89DB] font-[600]">
                                                    {carItem?.fixedRevenue * carItem?.horizonRefund}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="w-full flex flex-col items-start">
                                            <div className="flex flex-row items-center">
                                                <div className="w-[44px] h-[44px] bg-background4 rounded-full flex flex-col items-center justify-center">
                                                    <img src={HandDollarImg} alt="car" />
                                                </div>
                                                <p className="text-black3 text-[20px] font-[600] font-Urbanist ml-3">
                                                    Cumulative profitability
                                                </p>
                                            </div>
                                            <div className="w-full mt-[20px]">
                                                <ReactApexChart
                                                    options={barChartOptions}
                                                    series={profitChartData}
                                                    type="bar"
                                                    width="100%"
                                                    height="200px"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="flex flex-col items-start">
                                            <div className="flex flex-row items-center">
                                                <div className="w-[44px] h-[44px] bg-background4 rounded-full flex flex-col items-center justify-center">
                                                    <img src={CarImg} alt="car" />
                                                </div>
                                                <p className="text-black3 text-[20px] font-[600] font-Urbanist ml-3">
                                                    Total Rentability
                                                </p>
                                            </div>
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] ">
                                                    Financed amount on Hulk Cars
                                                </p>
                                                <p className="font-Urbanist text-[16px] ">
                                                    {dollarFormatter2(carItem?.amountFunded)}
                                                </p>
                                            </div>
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] ">
                                                    Interests paid to Hulk Cars Holders per Year
                                                </p>
                                                <p className="font-Urbanist text-[16px] ">
                                                    {dollarFormatter(Number(((carItem?.amountFunded / 100) * carItem?.fixedRevenue).toFixed(2)))}
                                                </p>
                                            </div>
                                            <div className="w-full  flex flex-row items-center justify-between mt-[30px]">
                                                <p className="font-Urbanist text-[16px] text-[#2C89DB] font-[600]">
                                                    Distributed revenues per year
                                                </p>
                                                <p className="font-Urbanist text-[16px] break-words text-right  text-[#2C89DB] font-[600]">
                                                    {carItem?.fixedRevenue}% ({(carItem?.fixedRevenue / 12).toFixed(2)}%/month)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative w-full flex flex-row items-center justify-between bg-white mt-[30px] rounded-[12px] p-[20px]">
                                        <div className="w-full flex flex-col items-center md:items-start">
                                            {
                                                isTabletOrMobile &&
                                                <img src={People1Img} alt="people" />
                                            }
                                            <p className=" text-black3 text-[20px] font-[600] font-Urbanist text-center md:text-start">
                                                Do you have any questions?
                                            </p>
                                            <p className="w-[250px] text-unseletedText text-[14px] font-[500] font-Urbanist mt-[16px] text-center md:text-start">
                                                Our customer service is on hand to help you at any time.
                                            </p>
                                            <div className="w-[230px] flex flex-row gap-2 rounded-full mt-[28px] border-[1px] border-black border-opacity-10 bg-[#F2F4F6] p-[10px_16px]">
                                                <img src={CallingImg} alt="phone" />
                                                <p className="font-Urbanist text-black text-[16px] font-[500]">
                                                    I want to be called back
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            !isTabletOrMobile &&
                                            <>
                                                <div className="w-1/2">
                                                </div>
                                                <img src={PeopleImg} alt="people" className="absolute right-0" />
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    !isTabletOrMobile &&
                                    <div className="flex flex-col  md:w-1/2  ">
                                        <div className="w-full bg-white mt-[30px] rounded-[12px] p-[20px]">
                                            <div className="flex flex-row items-center justify-between" >
                                                <p className="font-Urbanist font-[600] text-[24px] text-black5">
                                                    {dollarFormatter(carItem?.amountFunded)}
                                                </p>
                                                <div className="flex items-center justify-center rounded-full bg-background5 p-[8px_12px] text-unseletedText font-Urbanist text-[16px] font-[600]">
                                                    {projectState[carItem?.status]}
                                                </div>
                                            </div>
                                            <p className="font-Urbanist text-unseletedText text-[16px] font-[500] mt-[12px]">
                                                Amount to fund
                                            </p>
                                            {(carItem?.status === 2) &&
                                                <>
                                                    <div className="flex flex-row items-center justify-between mt-[48px]" >
                                                        <p className="font-Urbanist font-[600] text-[16px] text-black5">
                                                            {dollarFormatter(carItem?.fundsRaised)} funded
                                                        </p>
                                                        <p className="font-Urbanist font-[600] text-[16px] text-unseletedText">
                                                            {carItem?.progress}%
                                                        </p>
                                                    </div>
                                                    <ProgressBar progress={carItem?.progress} />
                                                    <div className="w-full mt-[32px]">
                                                        <div className="w-full flex flex-row">
                                                            <CustomInput
                                                                placeholder="Buy Amount"
                                                                crossOrigin={undefined}
                                                                value={buyAmount > 0 ? buyAmount : ''}
                                                                className="w-full" size="lg"
                                                                onChange={(e: any) => setBuyAmount(e.target.value)}
                                                                readOnly
                                                            />
                                                            <CustomInput
                                                                placeholder="Fee"
                                                                crossOrigin={undefined}
                                                                value={feeAmount > 0 ? '+ ' + feeAmount : ''}
                                                                className="w-full" size="lg"
                                                                onChange={() => { }}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="w-full flex flex-row flex-wrap">
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] w-[60px] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.1)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.1%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.25)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.25%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(0.5)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 0.5%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(1)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 1%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border  border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(2)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 2%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => handleAddBuyAmount(5)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">+ 5%</div>
                                                            </CustomButton>
                                                            <CustomButton variant="outlined" className="flex-grow text-black rounded-none border border-[#b0bec5] h-[44px] p-3" onClick={() => setBuyAmount(0)}>
                                                                <div className="flex items-center justify-center text-nowrap text-[14px]">Clear</div>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                    <CustomButton variant="filled" className="bg-black w-full h-[50px] font-Montserrat text-white rounded-full normal-case text-[16px] font-[500] p-0 mt-[20px]" text="Buy Cars" onClick={handleBuyCar} />
                                                    <div className="flex flex-row items-center gap-20 mt-[32px]">
                                                        <div className="relative">
                                                            {carItem.investors?.length > 0 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[0].avatar || LogoImage} alt={carItem.investors[0].fullname} className="w-[48px] h-[48px] rounded-full border border-white" />
                                                            }
                                                            {carItem.investors?.length > 1 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[1].avatar || LogoImage} alt={carItem.investors[1].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[24px]" />
                                                            }
                                                            {carItem.investors?.length > 2 &&
                                                                <img crossOrigin={undefined} src={carItem.investors[2].avatar || LogoImage} alt={carItem.investors[2].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[48px]" />
                                                            }
                                                        </div>
                                                        <p className="font-Urbanist text-[16px] font-[600] text-unseletedText">
                                                            {carItem?.investerCount} investors have invested on Hulk
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                            <CustomButton variant="outlined" className=" rounded-[8px] border-[1.5px] border-opacity-10 border-black w-full h-[50px]  normal-case  p-0 mt-[32px]" text="" onClick={() => setIsSimulation(true)}>
                                                <div className="flex flex-row items-center justify-center">
                                                    <img src={CalcImg} alt="calculate" />
                                                    <p className="text-black5 text-[16px] font-[500] font-Urbanist ">
                                                        Simulate my investment
                                                    </p>
                                                </div>
                                            </CustomButton>
                                            <div className="w-full h-[1px] bg-mainback mt-[40px]">
                                            </div>
                                            <div className="flex flex-row gap-2 mt-[32px]">
                                                <img src={TimerImg} alt="timer" />
                                                <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                                    Collection open since {dayjs(carItem?.startAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.startAt).format(DATETIME_FORMAT.TIME_24)}. Will close on {dayjs(carItem?.endAt).format(DATETIME_FORMAT.SHORT_DATE2)} at {dayjs(carItem?.endAt).format(DATETIME_FORMAT.TIME_24)}.
                                                </p>
                                            </div>
                                            <div className="flex flex-row gap-2 mt-[32px]">
                                                <img src={DollarImg} alt="timer" />
                                                <p className="text-unseletedText font-Urbanist text-[16px] font-[500]">
                                                    Any amount invested will be refunded in full in the event of an unsuccessful fundraising.
                                                </p>
                                            </div>

                                        </div>

                                    </div>
                                }
                            </div>
                    }
                </div>
                <div className="text-start w-full max-w-[1200px] p-[20px] md:mt-10 md:px-0 font-Urbanist font-[500] text-[16px] ">
                    Hulk Cars a crowdfunding platform specialized in cars.<br />
                    WARNING: Our offers involve certain risks and in particular the risk of total or partial loss of the sums invested. In addition, past performance is no guarantee of future performance, which means that just because our default rate is 0% does not mean that we will never have an incident on a building. If you have any questions about the risks associated with our projects, contact us and our teams will take the time to answer your questions.
                </div>
            </div>

            <PhotoGallery
                open={isOpenGallery}
                photos={carItem?.photos}
                onClose={() => setIsOpenGallery(false)}
            />

            <SimulationDialog
                open={isSimulation}
                onClose={() => setIsSimulation(false)}
                fixedRevenue={carItem?.fixedRevenue}
                horizonRefund={carItem?.horizonRefund}
            />
        </>
    );
}