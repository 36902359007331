import { Input } from "@material-tailwind/react";

export default function CustomInput({ className, containerClassName, ...props }: { className?: string; [key: string]: any }): JSX.Element {
    return (
        <Input
            variant="outlined"
            crossOrigin={undefined}
            containerProps={{ className: `bg-[#FFFFFF] ${containerClassName}` }}
            className="w-full border border-[#b0bec5] !border-t-[#b0bec5] !focus:border-t-[#b0bec5] !active:border-t-[#b0bec5]" size="lg"
            {...props as any}
        ></Input>
    );
}