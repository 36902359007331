// @ts-nocheck

import { useEffect, useState } from "react";
import CustomButton from "../../../components/button";
import { Photo, RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import { getFileUrl } from "../../../utils/utils";

export default function PhotoGallery(props): JSX.Element {

    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    useEffect(() => {
        if (props.photos?.length > 0) {
            setPhotos(props.photos.map(item => ({
                src: getFileUrl(item.path),
                alt: item.name,
                width: item.width || 400,
                height: item.height || 300
            })));
        }
    }, [props.photos]);

    console.log(photos);
    return (
        <div className={`fixed top-0 left-0 z-50 w-full h-full overflow-auto bg-white ${props.open ? 'block opacity-100' : 'hidden opacity-0'} transition-all duration-500`} >
            <div className="w-full p-[32px] relative mt-[50px] md:mt-0">
                <div className="max-w-full w-[768px] mx-auto">
                    <RowsPhotoAlbum
                        photos={photos}
                        // targetRowHeight={150}
                        rowConstraints={{ maxPhotos: 2 }}
                        onClick={({ event, photo }) => {
                            if (event.shiftKey || event.altKey || event.metaKey) return;
                            event.preventDefault();
                            setSelectedPhoto(photo);
                        }}
                        // describe photo album size in different viewports
                        // sizes={{
                        //     size: "1168px",
                        //     sizes: [{ viewport: "(max-width: 1200px)", size: "calc(100vw - 32px)" }],
                        // }}
                        // re-calculate the layout only at specific breakpoints
                        breakpoints={[220, 360, 480, 600, 900, 1200]}
                    />

                </div>
            </div>
            <div className="absolute top-[32px] left-[32px]">
                <CustomButton variant="outlined"
                    className="bg-white w-[100px] h-[40px] font-Montserrat text-unseletedText normal-case text-[14px] font-[500] p-0"
                    onClick={() => props.onClose()}
                >
                    Go Back
                </CustomButton>
            </div>
            {selectedPhoto &&
                <div className="fixed top-0 left-0 w-full h-full bg-[#ffffffe0] p-[32px] cursor-zoom-out"
                    onClick={() => setSelectedPhoto(null)}>
                    <img src={selectedPhoto.src} className="block w-full h-full object-contain" alt="" />
                </div>
            }
        </div>
    );
}