import CustomButton from "../../../components/button";
import { useMediaQuery } from "react-responsive";
import { FAQlist } from "../../../utils/utils";
import FAQItem from "../../../components/FAQItem";
import { motion } from 'framer-motion'

export default function Questions(props: any) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' });

    const handleLogin = async () => {
    }


    return (
        <motion.div transition={{
            type: "spring",
            damping: 10,
            stiffness: 100
        }} className="flex flex-col items-center w-full bg-black2 p-[10px] md:py-[100px]">
            <div className="flex flex-col flex-wrap md:flex-row w-full 2xl:w-[1200px] justify-center 2xl:justify-between gap-[30px]">
                <div className="flex flex-col items-center md:items-start md:w-[360px] ">
                    <p className="font-Montserrat text-[20px] font-[700] text-primary mt-[40px] md:mt-0">
                        FAQ
                    </p>
                    <p className="font-Montserrat text-center md:text-start text-[23px] md:text-[36px] font-[700] text-white mt-8">
                        The most common questions
                    </p>
                    <p className="font-Montserrat text-center md:text-start text-[14px] md:text-[18px] font-[500] text-gray3 mt-4">
                        We rely on your feedback to answer your questions.
                    </p>
                    {
                        !isTabletOrMobile &&
                        <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[260px] h-[64px] font-Montserrat text-[white] normal-case text-[16px] font-[500] mt-4" text="Tell me more about Hulk" />
                    }
                </div>
                <div className="flex flex-col items-start mt-[20px]">
                    {
                        FAQlist.map((item, index) => {
                            return (
                                <FAQItem question={item.question} answer={item.answer} key={index} />
                            )
                        })
                    }
                    {
                        isTabletOrMobile &&
                        <div className="w-full flex flex-col items-center my-[50px]">
                            <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[260px] h-[64px] font-Montserrat text-[white] normal-case text-[16px] font-[500] mt-4" text="Tell me more about Hulk" />
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    )
}