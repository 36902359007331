import BookImg from "../../assets/image/account/book.svg"

type SlideItemProps = {
    image: string,
    title: string;
    // time: string;
    content: string;
    onClick: any
}


export default function SlideItem(props: SlideItemProps): JSX.Element {
    return (
        <div className="bg-background w-[330px] h-[365px] rounded-[16px] overflow-hidden cursor-pointer" onClick={() => props.onClick()}>
            <img src={props.image} alt="" className="max-w-full w-auto h-[200px] mx-auto" />
            <div className="p-[20px]">
                <div className="flex flex-row items-center justify-between">
                    <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[500]">
                        {props.title}
                    </p>
                    {/* <div className="flex flex-row items-center gap-2">
                        <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[600] ">
                            {props.time}
                        </p>
                        <img src={BookImg} alt="book" />
                    </div> */}
                </div>
                <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[500] mt-2 md:mt-4 h-[80px] overflow-hidden">
                    {props.content?.substring(0, 110) + " ..."}
                </p>
            </div>

        </div>
    );
}