// @ts-nocheck

import { useEffect, useState } from "react";
import { getBlogPageInfo, getCategories } from "../../services/axios";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/loading";
import { getFileUrl } from "../../utils/utils";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/constants";
import './blog.css';

export default function BlogDetail(props: any) {

    const navigate = useNavigate();
    const { blogId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        if (blogId) {
            loadCategories();
            loadBlogInfo();
        }
    }, [blogId]);

    const loadCategories = async () => {
        const res = await getCategories();
        if (res.status === 200) {
            setCategories(res.data.result);
        }
    }

    const loadBlogInfo = async () => {
        setIsLoading(true);
        const res = await getBlogPageInfo(blogId);
        if (res.status === 200) {
            setBlog(res.data.result);
        } else {
            // handleBack();
        }
        setIsLoading(false);
    }

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="flex flex-col items-center pb-[100px] pt-[140px] px-[20px]">
                <div className="w-full max-w-[800px]">
                    <div className="w-full flex flex-wrap justify-center gap-3">
                        {blog?.categories?.map((category: string) => (
                            categories?.find(item => item.name == category) ?
                                <div className="border border-gray rounded-md cursor-pointer px-3 flex flex-row gap-2 items-center">
                                    <div className="rounded-full w-2 h-2 bg-blue"></div>
                                    <span>{category}</span>
                                </div> : <></>
                        ))}
                    </div>
                    <p className="w-full text-[24px] text-center font-Urbanist font-[600] text-black mt-8">{blog?.title}</p>
                    <p className="w-full text-[14px] text-center font-Urbanist font-[500] text-black mt-6">
                        {dayjs(blog?.createdAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                        {blog?.author && `— ${blog?.author}`}
                    </p>
                </div>

                <div className="w-full max-w-[600px] flex flex-col mt-10">
                    <img src={getFileUrl(blog?.image?.path)} alt="blog" className="w-full max-h-[400px] rounded-[4px] cursor-pointer" />

                    {blog?.videoUrl &&
                        <div className="max-w-full mt-6 w-[350px] h-[250px] md:w-[727px] md:h-[404px]" >
                            <iframe className='video w-full h-full'
                                title='Youtube player'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={blog?.videoUrl}>
                            </iframe>
                        </div>
                    }

                    <div className="mt-8 blog-details" dangerouslySetInnerHTML={{ __html: blog?.description?.replace(/<p><\/p>/g, "<p>&nbsp;</p>") }}>
                    </div>
                </div>
            </div>
        </>
    )
}
