// @ts-nocheck

import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/constants";
import Pagination from "../../components/Pagination";
import LoadingSpinner from "../../components/loading";
import { getActivities } from "../../services/axios";


export default function Activities(): JSX.Element {

    const [activities, setActivities] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getActivities({
            page, pageSize: 20
        });
        if (res.status === 200) {
            setActivities(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setIsLoading(false);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex justify-center py-[100px]">
                <div className="max-w-full w-[1200px] flex flex-col items-center">
                    <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-[#1B1B1B1A]">
                        <div>
                            <div className="text-black text-[20px] font-Urbanist-Bold font-bold">
                                User Activity
                            </div>
                            <div className="text-[#7A7E82] text-[12px] font-[600]">
                                All activities of user
                            </div>
                        </div>
                    </div>


                    <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                        <div className="bg-white w-full p-[24px] rounded-[8px]">
                            <div className="flex flex-col w-full overflow-x-auto">
                                <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                    <p className="w-[30%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Date</p>
                                    <p className="w-[30%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Time</p>
                                    <p className="w-[40%] min-w-[250px] text-[#7A7E82] text-[14px] font-[500]">Activity</p>
                                </div>
                                {activities.map((item, index) =>
                                    <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                        <div className="w-[30%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                        </div>
                                        <div className="w-[30%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.TIME_24)}</p>
                                        </div>
                                        <div className="w-[40%] min-w-[250px]">
                                            <p className="text-[14px] font-[500] text-black">{item.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Pagination
                                totalCount={totalCount}
                                onGoToPage={loadPage}
                                pageSize={20}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
