import format from "format-number";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


import RocketIcon from '../assets/image/why/rocket.svg'
import SecurityIcon from '../assets/image/why/security.svg'
import DollarIcon from '../assets/image/why/dollor.svg'
import NetworkIcon from '../assets/image/why/network.svg'
import IncomeIcon from '../assets/image/why/income.svg'
import NFTIcon from '../assets/image/why/nft.svg'

import FileImg01 from "../assets/image/account/file01.png"
import FileImg02 from "../assets/image/account/file02.png"

import SlideImage01 from "../assets/image/slide/image01.png";
import SlideImage02 from "../assets/image/slide/image02.png";
import SlideImage03 from "../assets/image/slide/image03.png";
import SlideImage04 from "../assets/image/slide/image04.png";


export const menuList = [
    {
        href: "/#invest",
        name: 'Invest'
    },
    {
        href: "/#who",
        name: "Who are we?",
    },
    {
        href: "/#review",
        name: "Reviews",
    },
    {
        href: "/#contact",
        name: "To contact us"
    }
]

export const cardList = [
    {
        icon: RocketIcon,
        text: "From 10$",
        content: "It is not necessary to have a large budget to start your cars investments with Hulk.",
        info: "* investing involves risks"
    },
    {
        icon: DollarIcon,
        text: "Diversification",
        content: "Investing in car with Hulk is an excellent strategy to diversify your investment portfolio."
    },
    {
        icon: NetworkIcon,
        text: "Networking",
        content: "By investing with us, you join a community of investors and entrepreneurs."
    },
    {
        icon: IncomeIcon,
        text: "Attractive income",
        content: "Investing in automobiles can generate very attractive and recurring returns compared to car.",
        info: "* past performance does not guarantee future performance."
    },
    {
        icon: SecurityIcon,
        text: "Simple et accessible",
        content: "Easily invest in a car project in a few clicks, without having to worry about management."
    },
    {
        icon: NFTIcon,
        text: "NFT",
        content: "Automatically receive an NFT on the blockchain confirming the ownership of the vehicle, allowing you to easily resell it on the secondary market.",
    },
]

export const slideList = [
    {
        icon: SlideImage01,
        number: '01',
        text: "I select A car",
        content: "Explore our property investment opportunities carefully selected by our experts.",

    },
    {
        icon: SlideImage02,
        number: '02',
        text: "I invest * from 10$",
        content: "An investment accessible from €100 via bonds, offering easy diversification. You can claim your NFT at any time with your web wallet 3.",
        info: '* investing involves risks'
    },
    {
        icon: SlideImage03,
        number: '03',
        text: "I touch my rents",
        content: "Each month, your income is automatically transferred to your portfolio based on the number of bonds you have.",
    },
    {
        icon: SlideImage04,
        number: '04',
        text: "Monitor your investments",
        content: "Every month, your income is automatically deposited into your wallet based on the number of bonds you own.",
    }
]


export const trustList = [
    {

        text: "Member for several months",
        content: "Registered for several months, I am extremely satisfied! An incredible platform. I highly recommend!",
        name: "Eric"
    },
    {
        text: "It is undeniably one of the best.",
        content: "This is undoubtedly one of the best ways to invest in car. Sometimes things may not go as planned, but that is the risk inherent in any investment. You can trust them.",
        name: "Momo"
    },
    {
        text: "Access an automobile investment accessible to all.",
        content: "Access a car heritage for only 10$. Get an honest return without requiring any steps or waste of time on our part (management, property visits, negotiations, etc.).",
        name: "Julien..."
    },
    {
        text: "My experience so far has been excellent.",
        content: "trans_failed_en_Jusqu'à présent, une expérience très positive. Les revenus réels sont conformes aux prévisions. Une startup française basée à Montpellier. Longue vie à l'entreprise !",
        name: "Benoit"
    }
]


export const carsState = [
    'Pending',
    'Upcoming fundraising',
    'Collection in progress',
    'Goal achieved',
    'Rejected',
    'Refunded',
    'PAUSED',
]

export const projectState = [
    'PENDING',
    'APPROVED',
    'IN PROGRESS',
    'COMPLETED',
    'REJECTED',
    'REFUNDED',
    'PAUSED',
]


export const fileList = [
    {
        image: FileImg01,
        title: "Team Hulk",
        time: "5 min",
        content: "Lorem ipsum dolor sit amet consectetur. Mauris tellus ullamcorper nulla accumsan."
    },
    {
        image: FileImg02,
        title: "Team Hulk",
        time: "5 min",
        content: "Lorem ipsum dolor sit amet consectetur. Mauris tellus ullamcorper nulla accumsan."
    }
]


export const FAQlist = [
    {
        question: 'What is car crowdfunding in bonds?',
        answer: "Car crowdfunding in bonds involves raising funds for car-related projects by issuing bonds to investors. Issuers benefit from alternative funding sources, while investors receive returns in the form of interest payments and capital repayment. This model offers a diversified investment opportunity in the automotive industry.",
    },
    {
        question: 'What is a Hulker?',
        answer: "Hulker appears to be the name given to each participant in the Hulk Rent program. So, for example, if there are 100 investors in the Hulk Rent program, it would represent 100 Hulkers.",
    }, {
        question: 'What is an obligation?',
        answer: "When you own a Hulk car NFT, it represents your ownership of the digital vehicle associated with that car. This ownership is recorded immutably on the blockchain, ensuring that you are the sole owner of that car in the digital world. The blockchain technology provides transparency, security, and traceability of ownership, meaning that nobody else can claim ownership of that digital car without your consent. In summary, the car NFT functions as a digital ownership certificate, providing verifiable and indisputable proof of your ownership rights over the represented vehicle.",
    }
    , {
        question: 'How does Hulk work?',
        answer: "Hulk Cars allows investors to own automobiles in the form of tokenized tokens, and then these cars are leased out by the company to generate revenue. This provides an opportunity for investors to have exposure to automobiles as assets, while benefiting from the rental income generated by these vehicles. This approach combines asset tokenization with the car rental business model, which could offer benefits in terms of portfolio diversification and income generation.",
    }
    , {
        question: 'Is Hulk a licensed company?',
        answer: "Hulk Cars is a UK company has the legal rights to lease cars and is partnered with Stripe as a payment partner to receive payments from customers.",
    }
    , {
        question: 'How to invest?',
        answer: " To invest, you can do it in two ways: either you pay by credit card or bank transfer (the minimum is €100), or you pay by crypto in deposit USDC on your Solana wallet, which is automatically created and associated with your Hulk Cars account. Find our YouTube tutorials for more explanation.",
    }
    , {
        question: 'What is the taxation?',
        answer: "The tax regulations vary by country. We advise you to consult a tax expert or research online for more information.",
    }
]


export const isValidateEmail = (email: any) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const showConfirmBox = (title: any, text: any, callback: Function) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonColor: "#44972a",
        confirmButtonText: "Yes",
        cancelButtonColor: "#FF5C5C",
        cancelButtonText: "No",
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            if (callback) {
                callback(result);
            }
        } else {
            MySwal.close();
        }
    });
}

export const getFileUrl = (filepath: any) => (filepath?.startsWith("http") ? filepath : process.env.REACT_APP_SERVER_STOR_URL + filepath);

export const dollarFormatter = format({ prefix: "$", integerSeparator: "," });
export const dollarFormatter2 = format({ prefix: "$", integerSeparator: ",", truncate: 2, padRight: 2 });
export const dollarFormatter3 = format({ prefix: "$", integerSeparator: ",", truncate: 3, padRight: 3 });
export const dollarFormatter4 = format({ prefix: "$", integerSeparator: ",", truncate: 4, padRight: 4 });

export const getGoogleUrl = (from: string) => {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

    const options = {
        redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT as string,
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
        ].join(' '),
        state: from,
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs.toString()}`;
};


const isDevnet = false;

export const getBlockchainHashLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/tx/${address}?cluster=devnet`;
    }
    return `https://solscan.io/tx/${address}`;
}
export const getBlockchainAccountLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/address/${address}?cluster=devnet`;
    }
    return `https://solscan.io/account/${address}`;
}
export const getBlockchainTokenLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/address/${address}?cluster=devnet`;
    }
    return `https://solscan.io/token/${address}`;
}

export const getTrimedAddress = (address: any) => {
    if (!address) {
        return "";
    }
    return address.substring(0, 6) + "......" + address.substr(-6);
}
