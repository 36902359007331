// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import CopyIcon from "../../../assets/image/copy2.svg";
import TelegramIcon from "../../../assets/image/icons/telegram.png";
import TwitterIcon from "../../../assets/image/icons/twitter.png";
import DiscordIcon from "../../../assets/image/icons/discord.png";
import { dollarFormatter2 } from "../../../utils/utils";
import { useCopyToClipboard } from "usehooks-ts";
import { toast } from "react-toastify";
import { getAffiliationEarned } from "../../../services/axios";


export default function MyLink(): JSX.Element {

    const { user } = useAuth();
    const [copiedText, copy] = useCopyToClipboard();

    const [earned, setEarned] = useState(0);

    useEffect(() => {
        getAffiliationEarned()
            .then(res => {
                setEarned(res.data.amount);
            })
            .catch(err => { });
    }, []);

    const handleCopyCode = () => {
        copy(user.referralCode)
            .then(() => {
                toast.success("Copied Code!");
            })
            .catch(error => { });
    }
    const handleCopyLink = () => {
        const link = `https://invest.hulkcars.com/ref/${user.referralCode}`;
        copy(link)
            .then(() => {
                toast.success("Copied Link!");
            })
            .catch(error => { });
    }

    return (
        <div className="max-w-full w-[760px] flex flex-col justify-center items-center gap-6">
            <div className="text-[#555555] text-[20px] text-center">
                Every time someone rents a vehicle or<br />
                rents their own vehicle using your referral link, you receive 3% of the buy amount car.
            </div>
            <div className="text-[#555555] text-[32px] font-bold">
                {dollarFormatter2(earned)} earned
            </div>
            <div className="w-full bg-[#F5F8FF] p-10 rounded-md">
                <div className="text-black text-[16px] font-[400] mb-3">Referral Code</div>
                <div className="w-full relative">
                    <input type="text"
                        value={user.referralCode}
                        onChange={(e) => { }}
                        readOnly
                        className={`w-full h-[45px] py-3 pl-3 pr-10 rounded-[8px] border-0 text-[#C0C0C0] text-[16px] outline-none`}
                    />
                    <button className="absolute w-[45px] h-[45px] rounded-[8px] top-0 right-0 bg-black flex items-center justify-center" onClick={handleCopyCode}>
                        <img src={CopyIcon} alt="copy" />
                    </button>
                </div>

                <div className="text-black text-[16px] font-[400] mt-5 mb-3">Referral Link</div>
                <div className="w-full relative">
                    <input type="text"
                        value={`https://invest.hulkcars.com/ref/${user.referralCode}`}
                        onChange={(e) => { }}
                        readOnly
                        className={`w-full h-[45px] py-3 pl-3 pr-10 rounded-[8px] border-0 text-[#C0C0C0] text-[16px] outline-none`}
                    />
                    <button className="absolute w-[45px] h-[45px] rounded-[8px] top-0 right-0 bg-black flex items-center justify-center" onClick={handleCopyLink}>
                        <img src={CopyIcon} alt="copy" />
                    </button>
                </div>
            </div>
            <div className="text-[#ACACAC] text-[16px] text-center">
                share on social media
            </div>
            <div className="flex gap-4 justify-center">
                <a href="https://t.me/hulkcars/" target="_blank" rel="noreferrer">
                    <img src={TelegramIcon} alt="telegram" className="w-[30px] h-[30px]" />
                </a>
                <a href="https://twitter.com/HulkCars" target="_blank" rel="noreferrer">
                    <img src={TwitterIcon} alt="twitter" className="w-[30px] h-[30px]" />
                </a>
                <a href="https://discord.com/invite/XXzJ7zqt67" target="_blank" rel="noreferrer">
                    <img src={DiscordIcon} alt="discord" className="w-[30px] h-[30px]" />
                </a>
            </div>
        </div>
    );
};
