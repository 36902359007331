import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserInfo } from "../../services/axios";
import { useAuth } from "../../hooks/useAuth";
import LoadingSpinner from "../../components/loading";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

export default function OAuthResult(props: any) {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { login, logout } = useAuth();

    useEffect(() => {
        let result = searchParams.get("result");
        if (Number(result) !== 1) {
            let msg = searchParams.get("msg");
            toast.error(msg);
            logout();
            navigate("/");
        } else {
            const token = Cookies.get('access_token');
            console.log("🚀 ~ useEffect ~ token:", token)
            loadUserInfo(token);
        }
    }, [searchParams]);

    const loadUserInfo = async (token: any) => {
        const res = await getUserInfo(token);
        if (res.status === 200) {
            login({
                ...(res.data),
                token: token
            });
        } else {
            navigate("/");
        }
    }

    return (
        <div className="flex flex-col items-center">
            <LoadingSpinner visible={true} />
        </div>
    )
}