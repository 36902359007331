import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import { fileList, getFileUrl } from "../../utils/utils";
import { useMediaQuery } from "react-responsive";
import SlideItem from "./slideitem";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function SlideShow(props: any): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })

    const handleClick = (item: any) => {
        window.open(`/blogs/${item._id}`, '_blank');
    }
    return (
        <div className="w-[350px] md:w-[700px] mt-[74px] ">
            <div className="flex flex-row items-center justify-between">
                <p className="font-Urbanist font-[600] text-[24px] text-black5">
                    {t('acc_ourfiles')}
                </p>
                <p className="underline font-Urbanist font-[500] text-[16px] text-black cursor-pointer"
                    onClick={() => {
                        window.open("/blogs", "blogs");
                    }}
                >
                    {t('acc_viewfiles')}
                </p>
            </div>
            <Swiper
                className="w-full flex flex-row justify-between gap-5 mt-6"
                spaceBetween={50}
                slidesPerView={isTabletOrMobile ? 1 : 2}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                navigation={false}
                modules={[Autoplay]}
                onSlideChange={() => { }}
                onSwiper={(swiper: any) => { }}
            >
                {
                    props.blogs?.map((item: any, index: any) => {
                        return <SwiperSlide key={index}  >
                            <SlideItem image={getFileUrl(item.image?.path)} title={item.title} content={item.excerpt} onClick={() => handleClick(item)} />
                        </SwiperSlide>
                    })
                }
            </Swiper>

        </div>

    );
}