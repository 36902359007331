import PhoneIcon from '../../assets/image/phone.svg'
import MessageIcon from '../../assets/image/message.svg'
import CustomButton from '../../components/button'
import LinkedinImg from '../../assets/image/social/linkedin.png'
import TiktokImg from '../../assets/image/social/tiktok.png'
import TwitterImg from '../../assets/image/social/twitter.png'
import FacebookImg from '../../assets/image/social/facebook.png'
import DiscordImg from '../../assets/image/social/discord.png'
import InstagramImg from '../../assets/image/social/instagram.png'
import MobileImg from '../../assets/image/mobile.png'
import LogoImg from '../../assets/image/logo.png'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

export default function Footer(props: any) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })
    const navigate = useNavigate();

    const handleLegalNotices = () => {
        navigate('/legal')
    }

    const handleTermsServices = () => {
        navigate('/terms')

    }
    const handlePrivacyPolicy = () => {
        navigate('/privacy')
    }

    const handleComplaint = () => {
        navigate('/complaint')
    }

    const handleHome = () => {
        window.location.href = 'https://invest.hulkcars.com/';
    }

    const handleLinkedin = () => {
        window.open(' https://www.linkedin.com/company/hulk-cars/');
    }

    const handleTwitter = () => {
        window.open('https://twitter.com/HulkCars');
    }

    const handleDiscord = () => {
        window.open('https://discord.gg/XXzJ7zqt67');
    }

    const handleInstagram = () => {
        window.open('https://www.instagram.com/hulk_cars_off');
    }

    const handleCall = () => {
        window.open('https://calendly.com/hulkcars/30min');
    }


    return (
        <div className="w-full bg-white flex flex-col items-center p-[10px]" id="contact">
            <div className="w-full max-w-[1200px]">
                <div className="flex flex-col">
                    <div className="flex flex-col flex-wrap md:flex-row items-start gap-[10px] md:items-center justify-between mt-[20px] md:mt-[80px]">
                        <div className="flex flex-col items-start">
                            <p className="text-black2 md:text-primary text-[18px] font-[600] font-Montserrat">
                                Customer Service
                            </p>
                            <p className="text-black2 md:text-primary text-[14px] font-[500] font-Montserrat">
                                Monday to Friday, from 9:00 to 13:00 without appointment
                            </p>
                        </div>
                        <div className="flex flex-row items-center">
                            <img src={PhoneIcon} alt="phone" />
                            <p className="text-primary text-[14px] font-[500] font-Montserrat ml-4">
                                {/* 04 00 00 00 00 */}
                            </p>
                        </div>

                        <div className="flex flex-row items-center">
                            <img src={MessageIcon} alt="message" />
                            <p className="text-primary text-[14px] font-[500] font-Montserrat ml-4">
                                Invest@hulkcars.com
                            </p>
                        </div>
                        <CustomButton onClick={handleCall} variant="filled" className="bg-primary w-[200px] h-[44px] font-Montserrat text-white normal-case text-[14px] font-[600] p-[10px]" text="Make an appointment" />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row items-center justify-between gap-3'>
                    <div className='relative flex flex-col md:flex-row items-start bg-background w-full xl:w-[480px] md:h-[217px] mt-[40px] rounded-[10px] p-[24px]'>
                        <div>
                            <p className='text-primary text-[18px] font-Montserrat font-[600]'>
                                Follow us on social networks
                            </p>
                            <div className='flex flex-row items-center mt-[25px]'>
                                <div className='grid grid-cols-3 gap-2'>
                                    <button onClick={handleInstagram}><img src={InstagramImg} alt="instagram" /></button>
                                    <button><img src={TiktokImg} alt="tiktok" /></button>
                                    <button onClick={handleTwitter}><img src={TwitterImg} alt="twitter" /></button>
                                    <button onClick={handleLinkedin}><img src={LinkedinImg} alt="instagram" /></button>
                                    <button><img src={FacebookImg} alt="tiktok" /></button>
                                    <button onClick={handleDiscord}><img src={DiscordImg} alt="twitter" /></button>
                                </div>
                            </div>
                        </div>
                        {
                            !isTabletOrMobile ?
                                <img src={MobileImg} className='md:absolute right-0' alt="mobile" />
                                :
                                <img src={MobileImg} className='absolute w-[139px] h-[157px] bottom-0 right-0' alt="mobile" />
                        }
                    </div>
                    <div className='flex flex-col items-start bg-background w-full xl:w-[690px] h-[217px] mt-[40px] rounded-[10px] p-[24px]'>
                        <p className='text-primary text-[18px] font-Montserrat font-[600] mt-[10px]'>
                            Are you a car dealer?
                        </p>
                        <p className='text-primary text-[14px] font-Montserrat font-[400] my-[20px]'>
                            Offer your vehicles to our platform today to collect funds
                        </p>
                        <CustomButton onClick={handleCall} variant="filled" className="bg-primary w-[200px] h-[56px] font-Montserrat text-white normal-case text-[14px] font-[600] p-[10px]" text="Submit a project" />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row items-center justify-between mt-[28px]'>
                    <button className='w-full flex flex-wrap items-center' onClick={handleHome}>
                        <img src={LogoImg} alt="logo" />
                        <p className='font-Montserrat-Bold text-[22px] md:text-[32px] font-[800] ml-[18px]'>
                            Hulk Cars
                        </p>
                    </button>
                    <div className='w-full flex flex-col md:flex-row items-start md:items-center gap-[10px] md:gap-[38px] mt-[20px]'>
                        <button className='font-Montserrat text-primary text-[12px] font-[500]' onClick={handleLegalNotices}>
                            Legal notices
                        </button>
                        <button className='font-Montserrat text-primary text-[12px] font-[500]' onClick={handlePrivacyPolicy}>
                            Confidentiality
                        </button>
                        <button className='font-Montserrat text-primary text-[12px] font-[500]' onClick={handleTermsServices}>
                            Terms and Conditions
                        </button>
                        <button className='font-Montserrat text-primary text-[12px] font-[500]' onClick={handleComplaint}>
                            Complaint
                        </button>

                    </div>
                </div>
                {
                    !isTabletOrMobile &&
                    <div className=' w-full border-[0.5px] text-black4 mt-[12px]'>
                    </div>
                }
                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[20px]'>
                    Investing in car bonds carries risks, such as not receiving the expected rents, or losing some or all of the capital Invested. Invest only the money you don't need right away, and diversify your investments.
                </p>
                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[20px]'>
                    Hulk is a crowdfunding platform specialized in automotive. Onramper is a payment institution headquartered at Onramper Payments Europe, B.V. Singel 542, 1017 AZ. Amsterdam, Netherlands.
                </p>
                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[20px]'>
                    Disclaimer: our offers carry risks, including the risk of total or partial loss of funds invested. In addition, past performance does not guarantee future performance. If you have any questions about the risks associated with our projects, do not hesitate to contact us. Our teams are available to answer your questions.
                </p>
                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[20px]'>
                    Crowdfunding services are not covered by the deposit guarantee scheme established under directive 2014/49/ eu and securities
                </p>
                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[20px]'>
                    Or instruments admitted for crowdfunding purposes acquired through their crowdfunding platform are not covered by the system
                    Investor compensation established in accordance with directive 97/9/ ec.
                </p>

                <p className='font-Montserrat text-[14px] font-[400] text-black3 mt-[90px] mb-[20px]'>
                    © Copyright - Invest.hulkcars.com
                </p>
            </div>
        </div>
    )
}