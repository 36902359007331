// @ts-nocheck
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserNFts } from "../../services/axios";
import { getFileUrl } from "../../utils/utils";

export default function Documents(): JSX.Element {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        loadDocuments();
    }, []);

    const loadDocuments = async () => {
        const res2 = await getUserNFts({});
        if (res2.status === 200) {
            const data = [];
            for (const item of res2.data.items) {
                if (item.car?.proofDoc) {
                    data.push({
                        carName: item.car?.name,
                        docName: "Proof of Purchase",
                        path: item.car?.proofDoc.path
                    });
                }
                if (item.car?.investSheet) {
                    data.push({
                        carName: item.car?.name,
                        docName: "Information Sheet",
                        path: item.car?.investSheet.path
                    });
                }
                if (item.car?.nftTerms) {
                    data.push({
                        carName: item.car?.name,
                        docName: "Terms and Conditions of NFT",
                        path: item.car?.nftTerms.path
                    });
                }
                if (item.car?.registrationDoc) {
                    data.push({
                        carName: item.car?.name,
                        docName: "Car Registration",
                        path: item.car?.registrationDoc.path
                    });
                }
            }
            setDocuments(data);
        }
    }

    return (
        <>
            <div className="flex flex-col items-center pt-[100px] pb-[100px] px-[20px]">
                <div className="mb-[90px] w-full md:w-[1000px]">
                    <div color="black" className="mt-[50px] pb-2 text-[32px] font-bold">My documents</div>
                    <div className="mt-6 flex flex-col gap-5">
                        {documents.length === 9 &&
                            <div className="p-5 bg-white rounded-[16px]">
                                <span className="text-black font-bold">Contracts</span>
                                <p color="gray-primary" className="">Find contracts from your cars investments</p>
                                <div className="pt-[48px]">
                                    <span color="black" className="">✍️ No contract</span>
                                </div>
                            </div>
                        }
                        {documents.map(item => (
                            <div className="p-5 bg-white rounded-[16px]">
                                <span className="text-black font-bold">{item.carName}</span>
                                <p color="gray-primary" className="">{item.docName}</p>
                                <div className="pt-[20px]">
                                    <a color="black" className="" href={getFileUrl(item.path)} target="_blank" rel="noreferrer">✍️ View document</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}