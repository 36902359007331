import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import SwiperButton1 from "./swiper_button1";
import { trustList } from "../utils/utils";
import TrustCard from "./trust_card";
import { useMediaQuery } from 'react-responsive';

export default function CustomCarosuel2(): JSX.Element {

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1028px)' });

    const slidesPerView = isMobile ? 1.2 : (isTablet ? 2.5 : 3.5);

    return (
        <div className="w-full  mt-[54px] ">
            <Swiper
                className="w-full"
                spaceBetween={20}
                slidesPerView={'auto'}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                navigation={false}
                modules={[Autoplay]}
                onSlideChange={() => { }}
                onSwiper={(swiper: any) => { }}
            >
                {
                    trustList.map((item, index) => {
                        return <SwiperSlide key={index} className='!w-[286px]'>
                            <TrustCard key={index} text={item.text} content={item.content} name={item.name} />
                        </SwiperSlide>
                    })
                }
                <SwiperButton1 />
            </Swiper>

        </div>

    );
}