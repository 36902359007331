// @ts-nocheck

import React, { useEffect, useState } from "react";
import { getAffiliationRankings } from "../../../services/axios";
import { dollarFormatter2, getFileUrl } from "../../../utils/utils";


export default function Leaderboard(): JSX.Element {

    const [rankings, setRankings] = useState([]);

    useEffect(() => {
        loadRankings();
    }, []);

    const loadRankings = async () => {
        const res = await getAffiliationRankings();
        if (res.status === 200) {
            setRankings(res.data.result);
        }
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-6">
            <div className="bg-white max-w-full w-[600px] p-[24px] rounded-[8px]">
                <div className="flex flex-col w-full overflow-x-auto">
                    <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                        <p className="w-[40%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Username</p>
                        <p className="w-[20%] min-w-[80px] text-[#7A7E82] text-[14px] font-[500]">Rank</p>
                        <p className="w-[30%] min-w-[100px] text-[#7A7E82] text-[14px] font-[500]">Points</p>
                    </div>
                    {rankings.map((item, index) =>
                        <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                            <div className="w-[40%] min-w-[150px]">
                                <p className="text-[14px] font-[500] text-black flex items-center gap-3">
                                    <img src={getFileUrl(item.avatar)} alt="user" className="w-[30px] h-[30px] rounded-full" />
                                    {item.name}
                                </p>
                            </div>
                            <div className="w-[20%] min-w-[80px]">
                                <p className="text-[14px] font-[500] text-black">{index + 1}</p>
                            </div>
                            <div className="w-[30%] min-w-[100px]">
                                <p className="text-[14px] font-[500] text-black">{Number((item.amount).toFixed(2))}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
