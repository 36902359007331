// @ts-nocheck

import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/constants";
import LoadingSpinner from "../../components/loading";
import { getUserNFtTransactions } from "../../services/axios";
import { useParams } from "react-router-dom";
import { dollarFormatter2, getBlockchainHashLink, getTrimedAddress } from "../../utils/utils";


export default function NftTransaction(): JSX.Element {

    const { nftId } = useParams();
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadPage();
    }, [nftId]);

    const loadPage = async () => {
        setIsLoading(true);
        const res = await getUserNFtTransactions(nftId);
        if (res?.status === 200) {
            setTransactions(res.data.result);
        }
        setIsLoading(false);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex justify-center py-[100px]">
                <div className="max-w-full w-[1200px] flex flex-col items-center">
                    <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-[#1B1B1B1A]">
                        <div>
                            <div className="text-black text-[20px] font-Urbanist-Bold font-bold">
                                NFT Transactions
                            </div>
                            <div className="text-[#7A7E82] text-[12px] font-[600]">
                                All incomes of user
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                        <div className="bg-white w-full p-[24px] rounded-[8px]">
                            <div className="flex flex-col w-full overflow-x-auto">
                                <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                    <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Date</p>
                                    <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Time</p>
                                    <p className="w-[30%] min-w-[250px] text-[#7A7E82] text-[14px] font-[500]">Amount</p>
                                    <p className="w-[30%] min-w-[250px] text-[#7A7E82] text-[14px] font-[500]">Tx</p>
                                </div>
                                {transactions?.map((item, index) =>
                                    <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                        </div>
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.TIME_24)}</p>
                                        </div>
                                        <div className="w-[30%] min-w-[250px]">
                                            <p className="text-[14px] font-[500] text-black">{dollarFormatter2(item.amount)} USDC</p>
                                        </div>
                                        <div className="w-[30%] min-w-[250px]">
                                            <a className="text-[14px] font-[500] text-black cursor-pointer"
                                                href={getBlockchainHashLink(item.hash)} target="_blank" rel="noreferrer"
                                            >
                                                {getTrimedAddress(item.hash)}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
