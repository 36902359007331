// @ts-nocheck

import { useEffect, useState } from "react";
import BlogItem from "./BlogItem";
import { getBlogPages, getCategories } from "../../services/axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/loading";
import CustomButton from "../../components/button";
import SearchIcon from "../../assets/image/icons/search.svg";
import GridIcon from "../../assets/image/icons/grid.svg";
import ListIcon from "../../assets/image/icons/list.svg";

export default function BlogList(props: any) {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selCategories, setSelCategories] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [viewType, setViewType] = useState(0);    // 0 - grid, 1 - list

    useEffect(() => {
        loadCategories();
        loadPage(1);
    }, []);

    useEffect(() => {
        loadPage(1);
    }, [selCategories]);

    const loadCategories = async () => {
        const res = await getCategories();
        if (res.status === 200) {
            setCategories(res.data.result);
        }
    }

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getBlogPages('blog', {
            page, pageSize: 9, keyword, categories: selCategories
        });
        if (res.status === 200) {
            if (page === 1) {
                setDataList(res.data.items);
            } else {
                setDataList([...dataList, ...(res.data.items)]);
            }
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
        setTimeout(() => setIsLoading(false), 500);
    }

    const handleSelectCategory = (category) => {
        if (selCategories.includes(category)) {
            setSelCategories(selCategories.filter(cat => cat !== category));
        } else {
            setSelCategories([...selCategories, category]);
        }
    }

    const handleViewDetail = (item: any) => {
        navigate(`/blogs/${item._id}`);
    }

    const handleLoadMore = () => {
        loadPage(curPage + 1);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="flex flex-col items-center px-[20px] pb-[100px] pt-[120px]">

                <div className="w-full max-w-full md:w-[1280px] flex flex-col justify-start mb-10">
                    <div className="text-black font-bold text-[18px] mb-3 self-start">
                        Categories
                    </div>
                    <div className="w-full flex justify-start gap-3 flex-wrap mb-4">
                        {categories?.map((category: string) => (
                            <div className="border border-gray rounded-md cursor-pointer px-3 flex flex-row gap-2 items-center" onClick={() => handleSelectCategory(category.name)}>
                                <div className={`rounded-full w-2 h-2 ${selCategories.includes(category.name) ? 'bg-blue' : 'bg-gray'}`}></div>
                                <span>{category.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className="w-full rounded-sm bg-white px-4 py-2 flex justify-between">
                        <div className={`relative max-sm:w-[100%]`}>
                            <input type="text"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                placeholder={"Search keyword"}
                                className={`w-full h-[36px] pl-10 py-1 pr-3 rounded-[8px] border border-gray text-[#86888C] text-[16px] outline-none`}
                                onKeyUp={e => e.key === "Enter" && loadPage(1)}
                            />
                            <img src={SearchIcon} alt="search" className="absolute top-2 left-3" />
                        </div>
                        <div className="flex gap-3">
                            <div className={`cursor-pointer w-8 h-8 rounded-sm p-1 hover:bg-[#F5F5F5] ${viewType === 0 && 'bg-[#F5F5F5]'}`}
                                onClick={() => setViewType(0)}
                            >
                                <img src={GridIcon} alt="grid" className="w-6 h-6" />
                            </div>
                            <div className={`cursor-pointer w-8 h-8 rounded-sm p-1 hover:bg-[#F5F5F5] ${viewType === 1 && 'bg-[#F5F5F5]'}`}
                                onClick={() => setViewType(1)}
                            >
                                <img src={ListIcon} alt="grid" className="w-6 h-6" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`w-full max-w-full md:w-[1280px] grid grid-cols-1 ${viewType === 0 && 'lg:grid-cols-2 2xl:grid-cols-3'} gap-8 pb-[50px]`}>
                    {
                        dataList?.map((item, index) => {
                            return (
                                <div className="flex justify-center">
                                    <BlogItem
                                        key={index}
                                        itemInfo={item}
                                        categories={categories}
                                        onClick={() => handleViewDetail(item)}
                                        viewType={viewType}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                {totalCount > dataList.length &&
                    <div>
                        <CustomButton onClick={handleLoadMore} variant="filled" className="bg-primary w-[200px] h-[44px] font-Montserrat text-white normal-case text-[14px] font-[600] p-[10px]" text="Load More Posts" />
                    </div>
                }
            </div>
        </>
    )
}
