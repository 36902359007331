import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '../utils/constants';

export default function Carosuel3(props: any): JSX.Element {


    return (
        <div className="w-full project-timeline">
            <Swiper
                className="w-full"
                spaceBetween={50}
                slidesPerView={3.1}
                loop={false}
                navigation={true}
                modules={[Autoplay, Navigation]}
                onSlideChange={() => { }}
                onSwiper={(swiper: any) => { }}
            >
                {props.startAt &&
                    <SwiperSlide >
                        <div className="flex flex-col items-center">
                            <div className="h-[24px] w-[1px] bg-[#E5E5E5]">
                            </div>
                            <p className="font-Urbanist text-[14px] font-[600] text-primary">
                                {dayjs(props.startAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                            </p>
                            <p className="text-unseletedText text-[14px] font-[500] font-Urbanist">
                                Start of the collection
                            </p>
                        </div>
                    </SwiperSlide>
                }
                {props.endAt &&
                    <SwiperSlide>
                        <div className="flex flex-col items-center">
                            <div className="h-[24px] w-[1px] bg-[#E5E5E5]">
                            </div>
                            <p className="font-Urbanist text-[14px] font-[600] text-primary">
                                {dayjs(props.endAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                            </p>
                            <p className="text-unseletedText text-[14px] font-[500] font-Urbanist">
                                End of collectio
                            </p>
                        </div>
                    </SwiperSlide>
                }
                {props.timeline?.map((item: any, index: any) =>
                    <SwiperSlide key={index}>
                        <div className="flex flex-col items-center">
                            <div className="h-[24px] w-[1px] bg-[#E5E5E5]">
                            </div>
                            <p className="font-Urbanist text-[14px] font-[600] text-primary">
                                {dayjs(item.date).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                            </p>
                            <p className="text-unseletedText text-[14px] font-[500] font-Urbanist">
                                {item.desc}
                            </p>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>

        </div >

    );
}